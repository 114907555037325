<template>
  <step-container
    :data="data"
    :on-submit="submit"
    :required-data="() => []"
  >
    <v-card flat>
      <custom-wording-data
        class="mx-4"
        :data="data"
      />
    </v-card>
  </step-container>
</template>

<script>
import UPDATE_COMPANY_CUSTOM_WORDING from './queries/UpdateCompanyCustomWording.gql'
import StepContainer from './StepContainer.vue'
import CustomWordingData from '@/components/forms/CustomWordingData'

export default {
  components: { StepContainer, CustomWordingData },
  props: {
    companyId: {
      type: String,
      required: true
    },
    salutation: {
      type: String,
      default: ''
    },
    farewell: {
      type: String,
      default: ''
    }
  },
  data () {
    return {
      data: {
        companyId: this.companyId,
        salutation: this.salutation,
        farewell: this.farewell
      }
    }
  },
  methods: {
    async submit ({ companyId, salutation, farewell }) {
      await this.$apollo.mutate({
        mutation: UPDATE_COMPANY_CUSTOM_WORDING,
        variables: {
          input: {
            companyId,
            salutation,
            farewell
          }
        }
      })
    }
  }
}
</script>
