<template>
  <v-dialog
    v-model="dialog"
    max-width="700"
    scrollable
  >
    <template v-slot:activator="{ on, attrs }">
      <v-btn
        outlined
        elevation="0"
        v-bind="attrs"
        class="mx-4 mb-2 no-text-transform"
        :class="{'btn-multiline': $vuetify.breakpoint.smAndDown}"
        :height="$vuetify.breakpoint.smAndDown ? 60 : 35"
        :disabled="!isLastRegenerationFinished"
        v-on="on"
      >
        <span
          v-if="!isLastRegenerationFinished"
          class="text-wrap"
        >Materialien werden aktuell neu generiert</span>
        <span
          v-else
          class="text-wrap"
        >Jetzt neu generieren</span>
      </v-btn>
    </template>

    <v-card>
      <v-card-title
        class="pa-0"
      >
        <v-toolbar
          color="primary"
          dark
          flat
        >
          <v-toolbar-title>Generierung</v-toolbar-title>
          <v-spacer />
          <v-toolbar-items>
            <v-btn
              icon
              dark
              @click="dialog = false"
            >
              <v-icon large>
                mdi-close
              </v-icon>
            </v-btn>
          </v-toolbar-items>
        </v-toolbar>
      </v-card-title>

      <v-card-text class="pa-6">
        <p class="font-weight-bold">
          Generierung von Dokumenten, Werbemitteln, Landingpages & E-Mail-Templates.
        </p>
        <Feature :feature-slug="featureNames.CONTRACT">
          <div>
            <p>Durch die Neugenerierung fallen folgende einmalige Zusatzkosten an:</p>
            <div class="pb-3 text-center font-weight-bold">
              Aktualisierung von Grundausstattungs-Werbemitteln / Ratgebern:
              <span class="pl-3 text-center font-weight-bold text-h6">
                {{ regenerationConditions.included ? 0 : regenerationConditions.price.value }} {{ regenerationConditions.price.currency }}
              </span>
            </div>
            <p v-if="company.productType === 'EXPERT' && regenerationConditions.included">
              <strong>Achtung:</strong><br>
              Diese Neugenerierung ist für Sie kostenlos. Jeder weitere Neugenerierung im laufenden Vertragshalbjahr kostet
              {{ regenerationConditions.price.value }} {{ regenerationConditions.price.currency }}.
            </p>
            <p v-if="company.productType === 'EXPERT' && !regenerationConditions.included">
              <strong>Achtung:</strong><br>
              Ihre letzte Neugenerierung liegt weniger als ein Vertragshalbjahr zurück.
              Daher fallen für diese Neugenerierung Ihrer Materialien Kosten in Höhe von
              {{ regenerationConditions.price.value }} {{ regenerationConditions.price.currency }} an.
            </p>
            <p v-if="company.productType === 'BASIC'">
              <strong>Achtung:</strong><br>
              Für diese Neugenerierung Ihrer Materialien fallen Kosten in Höhe von
              {{ regenerationConditions.price.value }} {{ regenerationConditions.price.currency }} an.
              Bei einem Upgrade auf das Expert-Paket ist eine Neugenerierung je Vertragshalbjahr kostenlos.
              Bei Interesse an einem Upgrade sprechen Sie bitte mit Ihrem Ansprechpartner.
            </p>
            <p>Diese werden Ihnen bei der nächsten Abrechnung über die von Ihnen gewählte Zahlweise in Rechnung gestellt.</p>
            <p>Durch Klick auf „Jetzt zahlungspflichtig bestellen“ beauftragen Sie diese Zusatzleistung verbindlich.</p>
          </div>
        </Feature>
        <p v-if="!isContractFeatueActive && hasRegenerationLimit">
          Die Dokumente, Werbemittel, Landingpages & E-Mail-Templates können <strong>{{ maxGenerationsPerYear }} mal pro Jahr</strong> neu generiert werden.
        </p>
        <p>
          Die Generierung kann einige Minuten dauern. Sie werden per E-Mail an <strong>{{ $auth.user.email }}</strong>
          informiert, sobald die Generierung abgeschlossen ist.
        </p>
        <p>
          Die bestehenden Dokumente werden hierbei überschrieben.
          Sollten Sie die bestehenden Dokumente in ihrer jetzigen Form abspeichern wollen, laden Sie diese bitte
          <strong> VOR </strong> der Neugenerierung herunter.
          <br>
          Die Generierung erfolgt ausschliesslich basierend auf den von Ihnen bereitgestellten Daten und Informationen.
          Eine inhaltliche, fachliche oder technische Prüfung der Daten erfolgt nicht.
        </p>
        <p>
          Bei fehlerhaften oder unvollständigen Angaben übernimmt {{ legalCompanyName }} keine Haftung.
        </p>
        <span v-if="!isBasicUser">Sie haben Fragen, oder möchten lieber noch einmal gemeinsam mit
          Ihren Kundenberater auf Ihre Daten schauen? Dann kontaktieren Sie uns:
        </span>
        <client-advisor
          class="mt-4 ma-lg-6"
          :company="company"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <div class="flex-column flex-lg-row">
          <v-btn
            text
            @click="dialog = false"
          >
            Abbrechen
          </v-btn>
          <v-btn
            text
            color="primary"
            :loading="isLoading"
            :disabled="isLoading"
            @click="submit"
          >
            <Feature :feature-slug="featureNames.CONTRACT">
              <div>
                Jetzt zahlungspflichtig bestellen
              </div>
            </Feature>
            <div v-if="!isContractFeatueActive">
              Jetzt Generieren
            </div>
          </v-btn>
        </div>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import START_REGENERATION from '../queries/StartRegeneration.gql'
import REGENERATION_CONDITIONS from '../queries/RegenerationConditions.gql'
import IS_LAST_REGENERATION_FINISHED from '../queries/IsLastRegenerationFinished.gql'
import ClientAdvisor from '@/components/ClientAdvisor.vue'
import { Feature } from 'feature-config'
import featureNames from '@/lib/featureNames'
import bus, { eventNames } from '@/lib/eventBus'
import brandingMixin from '@/mixins/branding'

export default {
  components: {
    Feature,
    ClientAdvisor
  },
  mixins: [brandingMixin],
  props: {
    company: {
      type: Object,
      required: true
    },
    isBasicUser: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      dialog: false,
      isLoading: false,
      regenerationConditions: {
        price: {}
      }
    }
  },
  computed: {
    hasRegenerationLimit () {
      return this.$features.feature(featureNames.REGENERATION)?.config?.hasLimit
    },
    maxGenerationsPerYear () {
      return this.$features.feature(featureNames.REGENERATION)?.config?.maxGenerationsPerYear
    },
    isContractFeatueActive () {
      return this.$features.feature(featureNames.CONTRACT).isActive
    },
    isGenerationDisabled () {
      return !this.isContractFeatueActive && !this.regenerationConditions.included
    }
  },
  created () {
    this.featureNames = featureNames
  },
  apollo: {
    regenerationConditions: {
      query: REGENERATION_CONDITIONS,
      variables () {
        return {
          companyId: this.$auth.user.companyId
        }
      }
    },
    isLastRegenerationFinished: {
      query: IS_LAST_REGENERATION_FINISHED,
      variables () {
        return { companyId: this.company.id }
      },
      skip () {
        return !this.company
      }
    }
  },
  methods: {
    async submit () {
      this.isLoading = true
      if (this.isGenerationDisabled) {
        bus.$emit(eventNames.SHOW_SNACKBAR, {
          color: 'error',
          text: 'Sie haben bereits 2 Generierungen in diesem Jahr durchgeführt. Bitte wenden Sie sich an Ihren Ansprechpartner.'
        })
        this.isLoading = false
        return
      }

      try {
        await this.$apollo.mutate({
          mutation: START_REGENERATION,
          variables: {
            companyId: this.company.id
          },
          refetchQueries: [{
            query: IS_LAST_REGENERATION_FINISHED,
            variables: { companyId: this.company.id }
          }]
        })
      } catch (err) {
        bus.$emit(eventNames.SHOW_SNACKBAR, { color: 'error', text: 'Die Generierung ist fehlgeschlagen.' })
      }
      this.isLoading = false
      this.dialog = false
    }
  }
}
</script>
