<template>
  <div>
    <div class="pb-4 mb-2">
      <h2 class="pr-4 ml-n2">
        Individualiserungen Ihrer E-Mails
      </h2>
    </div>
    <v-row class="d-flex justify-space-between">
      <v-col
        cols="12"
        md="6"
        class="pr-0 pa-0 pr-md-2"
      >
        <v-text-field
          ref="salutation"
          v-model.lazy="data.salutation"
          outlined
          label="Ansprache an Ihren Lead"
          placeholder="Guten Tag"
          validate-on-blur
        />
      </v-col>
      <v-col
        cols="12"
        md="6"
        class="pa-0"
      >
        <v-text-field
          ref="farewell"
          v-model.lazy="data.farewell"
          outlined
          label="Verabschiedung an Ihren Lead"
          placeholder="Auf Wiedersehen"
          validate-on-blur
        />
      </v-col>
    </v-row>
  </div>
</template>

<script>

export default {
  props: {
    data: {
      type: Object,
      default: () => ({})
    }
  }
}
</script>
