<template>
  <div v-if="company">
    <BaseHeader
      :title="company.name"
      :style-variant="14"
    >
      <template v-slot:design>
        <HeaderDesign />
      </template>

      <template v-slot:desc>
        <p>Titel, Anschrift, Farben und vieles mehr. Hier finden Sie alle Einstellungen rund um Ihre Firmendaten.</p>
      </template>
    </BaseHeader>

    <v-alert
      v-if="showRegeneration"
      border="left"
      type="warning"
    >
      <v-row
        class="py-1"
        :class="{'pl-4':$vuetify.breakpoint.smAndDown}"
      >
        Prima, Sie haben die Informationen zu Ihrem Unternehmen erfolgreich aktualisiert.
        Damit Ihre Änderungen nun auch in Ihren Ratgebern, Ihren Werbemitteln, auf Ihren Landingpages
        und den E-Mail-Funnels usw. erscheinen, sollten Sie diese nun neu generieren.
      </v-row>
      <v-row
        class="justify-end"
        :class="{'justify-center':$vuetify.breakpoint.smAndDown}"
      >
        <Regenrate
          :is-basic-user="isBasicUser"
          :company="company"
        />
      </v-row>
    </v-alert>

    <v-card
      outlined
      class="px-2 pt-2 px-md-4"
    >
      <v-tabs
        v-model="tab"
        show-arrows
      >
        <v-tab
          replace
          to="#companyData"
        >
          Firmendaten
        </v-tab>
        <v-tab
          replace
          to="#contact"
        >
          Ansprechpartner
        </v-tab>
        <v-tab
          replace
          to="#publication"
        >
          Publikation
        </v-tab>
        <v-tab
          v-if="isFeatureActive(featureNames.CONTRACT)"
          replace
          to="#billing"
        >
          Rechnungsdaten
        </v-tab>
        <v-tab
          v-if="isFeatureActive(featureNames.HOPPERMATION)"
          replace
          to="#custom-wording"
        >
          E-Mail Funnel
        </v-tab>
      </v-tabs>

      <v-divider class="mb-4" />

      <v-tabs-items
        v-model="tab"
        class="transparent"
      >
        <v-tab-item
          value="companyData"
        >
          <company-data v-bind="companyDataProps" />
        </v-tab-item>

        <v-tab-item
          value="contact"
        >
          <contacts v-bind="contactDataProps" />
        </v-tab-item>

        <v-tab-item
          value="publication"
        >
          <publication v-bind="publicationDataProps" />
        </v-tab-item>

        <v-tab-item
          v-if="isFeatureActive(featureNames.CONTRACT)"
          value="billing"
        >
          <billing-data v-bind="billingDataProps" />
        </v-tab-item>
        <v-tab-item
          v-if="isFeatureActive(featureNames.HOPPERMATION)"
          value="custom-wording"
        >
          <customWording-data v-bind="customWordingDataProps" />
        </v-tab-item>
      </v-tabs-items>
    </v-card>
  </div>
</template>

<script>
import COMPANY from '@/modules/company/queries/Company.gql'
import PENDING_CHANGES_FOR_REGENERATION from '@/queries/PendingChangesForRegeneration.gql'
import Contacts from './Contacts'
import Publication from './Publication'
import CompanyData from './CompanyData'
import BillingData from './BillingData'
import CustomWordingData from './CustomWordingData'
import Regenrate from './dialogs/Regenerate.vue'
import { isBasicUser } from '@/lib/productTypes'
import featureMixin from '@/mixins/feature'
import BaseHeader from '@/components/BaseHeader.vue'
import HeaderDesign from './HeaderDesign.vue'

export default {
  components: {
    Contacts,
    Publication,
    CompanyData,
    BillingData,
    CustomWordingData,
    Regenrate,
    BaseHeader,
    HeaderDesign
  },
  mixins: [featureMixin],
  data: () => ({
    tab: 'companyData',
    pendingChangesForRegeneration: {}
  }),

  computed: {
    showRegeneration () {
      return this.isFeatureActive(this.featureNames.REGENERATION) &&
      Object.keys(this.pendingChangesForRegeneration).length > 0
    },
    isBasicUser () {
      return isBasicUser(this.$auth.user)
    },
    companyDataProps () {
      const { id: companyId, name, address, links, commission } = this.company
      return { companyId, name, address, links, commission }
    },
    contactDataProps () {
      const { id: companyId } = this.company
      return { companyId }
    },
    customWordingDataProps () {
      const { id: companyId, customWording } = this.company
      const { salutation, farewell } = customWording || {}
      return { companyId, salutation, farewell }
    },
    publicationDataProps () {
      const {
        id: companyId, slogan, ambassador, name, links, address,
        images: { team, logo, office, region, lpValuationHero },
        theme, mailProvider,
        isReferenceCustomer,
        variant
      } = this.company
      ambassador.image = ambassador.image || {}
      ambassador.autograph = ambassador.autograph || {}
      theme.colors.primary = theme.colors.primary || '#000000'
      theme.colors.secondary = theme.colors.secondary || '#000000'
      return {
        companyId,
        slogan,
        ambassador,
        mailProvider,
        name,
        links,
        address,
        theme,
        isReferenceCustomer,
        variant,
        images: {
          team: team || {},
          logo: logo || {},
          office: office || {},
          region: region || {},
          lpValuationHero: lpValuationHero || {}
        }
      }
    },
    billingDataProps () {
      const { id: companyId, country, billing, customerNumber, address, name } = this.company
      return {
        companyId,
        country,
        address,
        name,
        customerNumber,
        billing: {
          ...billing,
          address: billing.address || {}
        }
      }
    }
  },
  apollo: {
    company: {
      query: COMPANY,
      variables () {
        return {
          id: this.$auth.user.companyId
        }
      }
    },
    pendingChangesForRegeneration: {
      query: PENDING_CHANGES_FOR_REGENERATION,
      variables () {
        return { companyId: this.company.id }
      },
      skip () {
        return !this.company
      }
    }
  }
}
</script>
